<template>
    <vx-card title="Supplier Return">
        <div class="flex vx-input-group">
            <vs-tabs v-if="render" v-model="$route.params.order">
                <!-- <vs-tab label="Planning">
                    <tab tab="planning"></tab>
                </vs-tab> -->
                <vs-tab label="Handover">
                    <tab tab="handover"></tab>
                </vs-tab>
                <vs-tab label="Completed">
                    <tab tab="completed"></tab>
                </vs-tab>
                <vs-tab label="Reversal">
                    <tab tab="reversal"></tab>
                </vs-tab>
                <vs-tab label="Reversed">
                    <tab tab="reversed"></tab>
                </vs-tab>
            </vs-tabs>
        </div>
    </vx-card>
</template>

<script>
import Tab from "./components/Tab.vue";
export default {
    components: {
        Tab,
    },
    data() {
        return {
            activeTab: "Planning",
            render: true,
        }
    },
    methods: {

    }
}
</script>